import axios from 'axios';
import { REPORT_LAMBDA_API_KEY, REPORTS_LAMBDA_URL } from '../../../constants/infra';

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const reportRequest = async (organizationId, filters, maxRetries = 3, initialRetryDelay = 1000) => {
  let attempt = 0;
  let retryDelay = initialRetryDelay;

  while (attempt < maxRetries) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const res = await axios.post(
        REPORTS_LAMBDA_URL,
        {
          organizationId,
          HYPERCUBE_API_KEY: REPORT_LAMBDA_API_KEY,
          CLIENT_MONTH: '3',
          LANG: 'en',
          ...filters,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
          },
          responseType: 'arraybuffer',
        },
      );

      return res;
    } catch (error) {
      attempt += 1;

      if (error.response && attempt < maxRetries) {
        delay(retryDelay);
        retryDelay *= 2;
      } else {
        throw error;
      }
    }
  }
  return null;
};

export default reportRequest;
